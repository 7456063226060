.footer {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: white;
  font-family: 'Gilda Display', serif;
  width: 100%;
}

.footer-left .logo-footer {
  width: 10px;
}

.footer-center {
  display: flex;
  flex-direction: column; 
  align-items: center; 
}

.footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-center a, .footer-right a {
  display: flex;
  align-items: center;
  margin: 5px 0; 
  color: white;
  text-decoration: none;
}

.footer-center a:hover, .footer-right a:hover {
  color: #ccc;
}

.icon {
  margin-right: 8px;
  font-size: 1.5rem; 
}


@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column; 
    align-items: center;
    text-align: center;
  }

  .footer-left, .footer-center, .footer-right {
    width: 100%;
    margin-bottom: 20px;
  }

  .footer-left .logo-footer {
    width: 60px;
  }

  .footer-center a, .footer-right a {
    justify-content: center;
  }

  .icon {
    font-size: 1.2rem;
  }
}
