.registro-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  text-align: center;
  position: relative;
  font-family: 'Gilda Display', serif;
}


h2 {
  text-align: center;
  font-family: 'Gilda Display', serif;
  color: #333;
}


.campo {
  margin-bottom: 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}


input[type="text"],
input[type="password"],
input[type="email"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
  font-family: 'Gilda Display', serif;
  font-size: 16px;
}


.boton2 {
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Gilda Display', serif;
  transition: background-color 0.3s ease;
}


.boton2:hover {
  background-color: #5c595f;
}


.boton2:active {
  transform: scale(0.96);
}


.registro-completado {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}


.tic {
  width: 30px;
  height: 30px;
  background-color: transparent;
  position: relative;
  transform: rotate(0deg);
  transform-origin: 50% 50%;
  animation: tic-anim 0.6s ease-in-out;
}


.brazo1,
.brazo2 {
  width: 3px;
  height: 12px;
  background-color: #4CAF50;
  position: absolute;
  top: 7px;
  left: 11px;
}

.brazo1 {
  transform: rotate(45deg);
}

.brazo2 {
  transform: rotate(-45deg);
}


.tic.mostrar {
  opacity: 1;
}


@keyframes tic-anim {
  0% {
    transform: scale(0);
  }
}

.captcha-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .registro-container {
    max-width: 100%; 
    padding: 15px; 
    margin-top: 10px; 
  }

  h2 {
    font-size: 1.5rem; 
  }

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    padding: 8px; 
    font-size: 0.9rem; 
  }

  .boton2 {
    padding: 8px; 
    font-size: 0.9rem; 
  }
}
