html, body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

.container {
  flex: 1; 
  display: flex;
  justify-content: center;
  align-items: center;
}


footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px; 
  background-color: #333;
  color: white;
  width: 97.1%;
  position: relative;
  bottom: 0;
  left: 0;
  margin-top: 182px; 
  font-size: 0.9rem; 
}

.footer-left .logo-footer {
  width: 70px; 
}

.footer-right a {
  margin-left: 10px; 
  color: white;
  text-decoration: none;
}

.footer-right a:hover {
  color: #ccc;
}
